import { render } from "./my.vue?vue&type=template&id=25560e6e&scoped=true"
import script from "./my.vue?vue&type=script&lang=js"
export * from "./my.vue?vue&type=script&lang=js"

import "./my.vue?vue&type=style&index=0&id=25560e6e&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-25560e6e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "25560e6e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25560e6e', script)) {
    api.reload('25560e6e', script)
  }
  
  module.hot.accept("./my.vue?vue&type=template&id=25560e6e&scoped=true", () => {
    api.rerender('25560e6e', render)
  })

}

script.__file = "src/views/my/myIndex/my.vue"

export default script