<template>
  <div class="my">
    <div class='top'>
      <!-- <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/message.png' @click='toDetail("notice")' class='message_png' /> -->
      <div class='user_cont'>
        <img :src='user.headimg?changeText(user.headimg):"https://h5.yihu365.cn/page/images/moren.png"' class='user_png' @click='toDetail("userset")' />
        <div class='user_name' @click='toDetail("userset")'>
          {{user.username?user.username:user.userMobile}}
        </div>
        <van-icon name="arrow" class='icon' @click='toDetail("userset")' />
      </div>
    </div>
    <div class='row_content'>
      <div class='list border_bottom' @click='toDetail("account")'>
        <div>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/account.png' class='list_icon' /> 我的账户
        </div>
        <van-icon name="arrow" />
      </div>
      <div class='list border_bottom' @click='toDetail("orderList")'>
        <div>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/account.png' class='list_icon' /> 我的订单
        </div>
        <van-icon name="arrow" />
      </div>
      <div class='list border_bottom' @click='toDetail("coupon")'>
        <div>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/coupon.png' class='list_icon' /> 代金券
        </div>
        <van-icon name="arrow" />
      </div>
      <div class='list border_bottom' @click='toDetail("address")'>
        <div>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/address.png' class='list_icon' /> 上门地址
        </div>
        <van-icon name="arrow" />
      </div>
      <div class='list border_bottom' @click='toDetail("patients")'>
        <div>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/idcard.png' class='list_icon' /> 患者管理
        </div>
        <van-icon name="arrow" />
      </div>
      <div class='list' @click.stop='conext()'>
        <div>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/kefu.png' class='list_icon' /> 官方客服
        </div>
        <van-icon name="arrow" />
      </div>

    </div>



    <div class='icon_cont' v-if='store.state.jumpMark == "1"' @click="toShops" >
      <img src='https://static.yihu365.cn/img/wx-uni/logo_kts.png' class='icon_logo'/>
    </div>
    <!-- <div @click='logout'>退出登录</div> -->

    <div class='dero_cont' v-if='store.state.jumpMark == "1"'>
      医护到家为众多用户提供护士上门、居家康复等医养健康服务，想接单的医护人员请下载"
      <a href='http://a.app.qq.com/o/simple.jsp?pkgname=com.vodone.o2o.health_care.provider' style='color:#5EACFF'>医护到家医护版手机APP</a>"
    </div>

  </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Dialog } from "vant";
import myApi from "@axios/my";
import { channel } from '@/config/channel.js';

export default {
  name: "my",
  components: {},

  data() {
    return {
      user: {
        username: "未登录",
        headimg: "https://h5.yihu365.cn/page/images/moren.png"
      }
    };
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();

    const toDetail = name => {
      router.push({
        name: name
      });
    };

    let token = encodeURI(store.state.token);
    console.log('token0',token);
    const toShops = () => {
      window.location.href = `https://yhim.yihu365.cn?token=${token}&userId=${store.state.uid}`;
    }

    return {
      channel,
      store,
      toDetail,
      toShops
    };
  },
  mounted() {

    const token = this.$store.state.token;
    console.log('token',token)
    // const userId = this.$store.state.userId
    const dt = {
      function: "getUserInfo",
      token: token,
      userid: this.$store.state.uid,
      login_userId_base: this.$store.state.uid,
      _validate: "1",
      _from: "h5",
      version: this.$store.state.version,
      newversion: this.$store.state.newversion,
    };
    let formData = {
      data : dt,
      encryption : false
    };

    this.getMy(formData);
  },
  methods: {
    conext(){
      window.location.href = 'tel://4008006996';
    },
    changeText(url){
      return 'https://' + url.split('//')[1]
    },
    getMy(formData) {
      myApi.getMy(formData)
        .then(res => {
          console.log(res);
          this.user.username = res.user.nickName;
          this.user.headimg = res.user.userHeadPicUrl;
          this.user.userMobile = res.user.userMobile;
        })
        .catch(res => {
          console.log(res);
          if (res.code == "0100") {
            this.$router.push({
              name: "login"
            });
          }
        });
    }
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.icon {
  position: relative;
  top: 3px;
}
.top {
  height: 400px;
  background: url(https://static.yihu365.cn/img/h5Img/assets/img/my/index_top.png) no-repeat center center;
  background-size: 100%;
  position: relative;
  padding-left: 50px;
}
.message_png {
  position: absolute;
  top: 40px;
  right: 63px;
  width: 40px;
}
.user_cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 125px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  .user_png {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    // border: 1px solid #000;
    margin-right: 24px;
  }
  .user_name {
    margin-right: 15px;
  }
}
.row_content {
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 646px;
  margin-left: 30px;
  position: relative;
  top: -67px;
  padding: 0 22px;
}
.list {
  height: 118px;
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  align-items: center;
  .list_icon {
    width: 40px;
    margin-right: 20px;
  }
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.border_bottom {
  border-bottom: 1px solid #ededed;
}
.icon_cont {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  img {
    width: 110px;

  }
}
.dero_cont {
  padding: 0 55px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  margin-top: 50px;
  padding-bottom: 200px;
}
</style>
